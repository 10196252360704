import { connect } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import { PanelGroup } from 'react-bootstrap';
import { Common } from 'wsm-common-data';
import { ErrorBoundary } from 'wsm-error-boundary';
import { setNewRelicCustomAttribute } from 'ddc-new-relic';
import logger from 'ws-scripts/modules/logger';
import { waitForInvData } from 'wsm-srp-utilities';
import { isBrowser } from 'ws-scripts/modules/environment';
import { EvModal } from 'wsm-common-modal';
import { addAllFacetItem } from '../utilities/add-all-facet-item';
import FacetGroup from '../components/FacetGroup';
import MultiFacetGroup from '../components/MultiFacetGroup';
import {
	fuelEfficiencyFacetIds,
	detailFacetLabels,
	detailFacetLabelsObject
} from '../global-constants';
import { arrayInsertSingle } from '../utilities/array-insert-single';
import '../components/style.scss';
import ClosestDealerWrapper from '../components/ClosestDealerWrapper';

class WidgetContainer extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			renderPortal: false,
			isOpenModal: false,
			isMutilFacetGroups: false,
			modalTitle: '',
			modalContent: {}
		};

		if (isBrowser) {
			waitForInvData(this, 'facet');
			waitForInvData(this, 'closestAccounts');
		}
		this.closeModal = this.closeModal.bind(this);
		this.openModal = this.openModal.bind(this);
	}

	componentDidMount() {
		if (isBrowser && window.DDC?.pubsub) {
			// Subscribe to ws-inv-filters modal display events
			window.DDC.pubsub.subscribe('ws-inv-filters/modal-show', () => {
				this.setState({ renderPortal: true });
			});
			window.DDC.pubsub.subscribe('ws-inv-filters/modal-hide', () => {
				this.setState({ renderPortal: false });
				if (typeof window.scrollTo !== 'undefined') {
					window.scrollTo(0, 0);
				}
			});
		}
	}

	// eslint-disable-next-line react/sort-comp
	openModal = (
		modalTitleMarkup = '',
		modalContentMarkup = {},
		isMutilFacetGroupsMarkup = false
	) => {
		this.setState({
			isOpenModal: true,
			modalTitle: isMutilFacetGroupsMarkup
				? modalTitleMarkup
				: modalContentMarkup.title,
			modalContent: modalContentMarkup,
			isMutilFacetGroups: isMutilFacetGroupsMarkup
		});
	};

	// eslint-disable-next-line react/sort-comp
	static formatFacetLabel(title) {
		return title.toLocaleUpperCase();
	}

	static mappingLabel(labels, facetId) {
		if (!detailFacetLabelsObject[facetId]) {
			return {};
		}
		const {
			detailLabel,
			subTextLabel,
			aboutLinkLabel,
			tilteLabel,
			learnMoreLinkLabel
		} = detailFacetLabelsObject[facetId];
		return {
			details: labels.get(detailLabel),
			subText: labels.get(subTextLabel),
			aboutLink: labels.get(aboutLinkLabel),
			title: labels.get(tilteLabel),
			learnMoreLink: labels.get(learnMoreLinkLabel),
			facetId
		};
	}

	static getModalContent(labels, facetId, isGroupFacet = false, mutilFacets) {
		if (isGroupFacet) {
			const evFacetSubContents = mutilFacets
				.map((subFacet) => {
					const facetLabel = WidgetContainer.formatFacetLabel(
						subFacet?.facet?.id
					);
					return {
						title: labels.get(`EV_${facetLabel}_TITLE_VERBIAGE`),
						details: labels.get(`EV_${facetLabel}_DETAILS_VERBIAGE`)
					};
				})
				.filter((item) => item.details);
			const facetLabelName = WidgetContainer.formatFacetLabel(facetId);
			return {
				mainDetail: labels.get(`EV_${facetLabelName}_DETAILS_VERBIAGE`),
				subContent: evFacetSubContents,
				subText: labels.get(`EV_${facetLabelName}_SUB_TEXT_VERBIAGE`)
			};
		}

		return WidgetContainer.mappingLabel(labels, facetId);
	}

	closeModal = () => {
		this.setState({
			isOpenModal: false
		});
	};

	getEvModalOptions = () => {
		const { isOpenModal, modalTitle, modalContent, isMutilFacetGroups } =
			this.state;
		return {
			isOpen: isOpenModal,
			onClose: this.closeModal,
			onOpen: this.openModal,
			title: modalTitle,
			children: modalContent,
			isMutilFacetGroups
		};
	};

	/**
	 * Create a FacetGroup component for each object in data.facets
	 *
	 * @param data {Object} InvData.getFacets() response
	 * @returns {FacetGroup[]} FacetGroup components
	 */
	static getFacetGroups(
		data,
		useMultifacetGroups,
		onOpen,
		isGeoLocationEnhanced
	) {
		const appliedFilters = window.DDC.InvData.getAppliedFilters();
		// The following is to support grouping facets together into a single panel.
		// The only group currently is for fuelEfficiency.
		// This should be expanded to support groups configurable in composer.

		/* TODO:
			Multi-facet groups should be configurable and the data
			should store this information.

			Each group should have:
			- facets contained in group
			- type of display per facet (select, radio, checkbox, etc.)
		*/

		// TODO: remove temporary variable for feature flag
		const multiFacetGroupIds = useMultifacetGroups
			? fuelEfficiencyFacetIds
			: [];

		const singleGroupFacets = data.facets.filter(
			(facet) => !multiFacetGroupIds.includes(facet.id)
		);

		let multiGroupInsertIndex = 0;
		let multiGroupPanelIsCollapsed = false;
		const multiGroupFacets = data.facets.filter((facet, idx) => {
			const isMultiGroupFacet = multiFacetGroupIds.includes(facet.id);
			// Get index of first facet as insert position for group
			if (isMultiGroupFacet && idx && !multiGroupInsertIndex) {
				multiGroupInsertIndex = idx;
			}

			// Set entire panel as collapsed if ANY of these facets are set to collapsed
			if (
				isMultiGroupFacet &&
				!multiGroupPanelIsCollapsed &&
				facet.collapsed
			) {
				multiGroupPanelIsCollapsed = true;
			}

			return isMultiGroupFacet;
		});

		const getFacetGroup = (facets, renderGroup = true) => {
			return facets.map((facet) => {
				const facetSelections = appliedFilters.find(
					(filter) => filter.id === facet.id
				);
				// Create "All" facet selection for single select facets
				// and set selected to true if no other facet values are selected
				// TODO THIS SHOULD BE ADDED TO THE FACET FROM WIS.
				if (!facet.multiselect) {
					// eslint-disable-next-line no-param-reassign
					facet.values = addAllFacetItem(
						facet.values,
						facet.allLabel
					);
				}
				const isDetailLink =
					detailFacetLabels.includes(facet.id) &&
					!fuelEfficiencyFacetIds.includes(facet.id);
				return renderGroup ? (
					<Common
						render={({ labels, flags, prefs, sitemap }) => {
							const showDetailLinkFacet =
								flags['enable-detail-link-facets'];
							const evInfoPageHrefAlias =
								facet.id === 'normalFuelType'
									? prefs.linkToEvInfoPageAlias
									: null;

							const evInfoPageHref =
								sitemap.get(evInfoPageHrefAlias);
							let evModalContent =
								showDetailLinkFacet && isDetailLink
									? WidgetContainer.getModalContent(
											labels,
											facet.id
									  )
									: null;
							if (evInfoPageHref) {
								evModalContent = {
									...evModalContent,
									evInfoPageHref
								};
							}

							// If href is not available hide learn more link
							if (!evInfoPageHref && evModalContent) {
								evModalContent.learnMoreLink = null;
							}

							return (
								<FacetGroup
									facet={facet}
									openDetailsModal={onOpen}
									detailsLinkData={evModalContent}
									selectedValues={
										facetSelections
											? facetSelections.values
											: []
									}
									key={`facetgroup-${facet.id}`}
									geoLocationEnhanced={isGeoLocationEnhanced}
								/>
							);
						}}
					/>
				) : (
					{
						facet,
						facetSelections
					}
				);
			});
		};
		const singlesGroup = getFacetGroup(singleGroupFacets);
		const multiFacets = getFacetGroup(multiGroupFacets, false);

		// Only fuel efficiency facets are supported currently
		// for multi-facet group
		const multiGroup = multiFacets.length ? (
			<Common
				render={({ labels, flags }) => {
					const showDetailLinkFacet =
						flags['enable-detail-link-facets'];
					const evModalContent =
						showDetailLinkFacet &&
						WidgetContainer.getModalContent(
							labels,
							'MPG_MPGE',
							true,
							multiFacets
						);
					return (
						<MultiFacetGroup
							facets={multiFacets}
							groupId="fuelEfficiency-group"
							groupTitleLabel="MPG_MPGE"
							openDetailsModal={onOpen}
							detailsLinkData={evModalContent}
							collapsed={multiGroupPanelIsCollapsed}
						/>
					);
				}}
			/>
		) : null;
		return arrayInsertSingle(
			singlesGroup,
			multiGroupInsertIndex,
			multiGroup
		);
	}

	static getFacetList(facets, options, closestAccounts, isRenderPortal) {
		const locationFacet =
			facets.facets.find((item) => item.id === 'accountId') || {};
		const { values: locationList } = locationFacet;
		const { prefs = {} } = closestAccounts || {};
		const { geoLocationEnhanced, showLocationTab } = prefs;
		const isGeoLocationEnhanced = geoLocationEnhanced === 'true';
		return (
			<ErrorBoundary
				errorHandler={(error, errorInfo) => {
					setNewRelicCustomAttribute(
						'SRP ERROR',
						`ws-inv-facets error boundary.\n${error}`
					);
					const newError = new Error(
						`ws-inv-facets error boundary.\n${error}`
					);
					newError.originalError = error;
					newError.originalStackTrace = errorInfo.componentStack;
					logger.error(`${newError}\n${newError.originalStackTrace}`);
				}}
			>
				{/* TODO: remove common render prop for feature flag */}
				<Common
					render={({ flags }) => {
						const useMultifacetGroups =
							flags['ws-inv-facets-use-muti-facet-groups'];
						return (
							<PanelGroup id="facet-list" className="facet-list">
								<EvModal {...options} />
								{isGeoLocationEnhanced && (
									<ClosestDealerWrapper
										closestAccounts={closestAccounts}
										isRenderPortal={isRenderPortal}
										locationList={locationList}
										showLocationTab={
											showLocationTab === 'true'
										}
									/>
								)}
								{WidgetContainer.getFacetGroups(
									facets,
									useMultifacetGroups,
									options.onOpen,
									isGeoLocationEnhanced
								)}
							</PanelGroup>
						);
					}}
				/>
			</ErrorBoundary>
		);
	}

	render() {
		const options = this.getEvModalOptions();
		const facets =
			(isBrowser &&
				window.DDC?.InvData?.srpReady &&
				window.DDC?.InvData?.getFacets &&
				window.DDC?.InvData?.getFacets()) ||
			undefined;
		if (!facets || Object.keys(window.DDC.InvData.facets).length === 0) {
			return null;
		}

		const closestAccounts =
			(isBrowser &&
				window.DDC?.InvData?.getClosestAccounts &&
				window.DDC?.InvData?.getClosestAccounts()) ||
			{};

		const [modalContainer] = document.querySelectorAll(
			'.modal-filters-react-portal'
		);

		const isRenderPortal = modalContainer && this.state.renderPortal;

		const facetList = WidgetContainer.getFacetList(
			facets,
			options,
			closestAccounts,
			isRenderPortal
		);

		// Renders the `facetList` into a modal based on event type
		if (isRenderPortal) {
			return ReactDOM.createPortal(facetList, modalContainer);
		}

		return facetList;
	}
}

WidgetContainer.propTypes = {};

const mapStateToProps = (_state) => {
	const {
		requestData: { widgetName }
	} = _state;
	return { widgetName };
};

const mapDispatchToProps = (_dispatch) => ({});

const Widget = connect(mapStateToProps, mapDispatchToProps)(WidgetContainer);

export default Widget;
