// This file is intended for global constants that are shared across
// the application. Use this file sparingly. For constants with a single
// usage, it can be better to include them in the module scope of the
// file they're used in.
export const noPrice = '0.0';
export const priceFacetIds = [
	'internetPrice',
	'msrp',
	'retailValue',
	'invoicePrice'
];
export const fuelEfficiencyFacetIds = [
	'combinedFuelEfficiency',
	'highwayFuelEconomy',
	'cityFuelEconomy'
];

export const detailLinkFacetIDs = ['rangeValue', 'range', 'normalFuelType'];
export const FACET_OVERRIDE_TYPE_SELECT = 'SELECT';
export const detailFacetLabels = [
	...detailLinkFacetIDs,
	...fuelEfficiencyFacetIds
];
export const detailFacetLabelsObject = {
	rangeValue: {
		tilteLabel: 'EV_RANGEVALUE_TITLE_VERBIAGE',
		detailLabel: 'EV_RANGEVALUE_DETAILS_VERBIAGE',
		subTextLabel: 'EV_RANGEVALUE_SUB_TEXT_VERBIAGE',
		aboutLinkLabel: 'EV_RANGEVALUE_ABOUT_VERBIAGE',
		learnMoreLinkLabel: ''
	},
	range: {
		tilteLabel: 'EV_RANGEVALUE_TITLE_VERBIAGE',
		detailLabel: 'EV_RANGEVALUE_DETAILS_VERBIAGE',
		subTextLabel: 'EV_RANGEVALUE_SUB_TEXT_VERBIAGE',
		aboutLinkLabel: 'EV_RANGEVALUE_ABOUT_VERBIAGE',
		learnMoreLinkLabel: ''
	},
	normalFuelType: {
		tilteLabel: 'EV_FUELTYPE_TITLE_VERBIAGE',
		detailLabel: 'EV_FUELTYPE_DETAILS_VERBIAGE',
		subTextLabel: '',
		aboutLinkLabel: 'EV_FUELTYPE_ABOUT_VERBIAGE',
		learnMoreLinkLabel: 'EV_FUELTYPE_LINK_VERBIAGE'
	}
};
