import React from 'react';
import PropTypes from 'prop-types';
import LocationItem from './LocationItem';
/* eslint react/no-danger: 0 */

const LocationList = ({ data }) => {
	return (
		<ul className="list-unstyled line-height-condensed">
			{data &&
				data.map((item, index) => (
					<LocationItem data={item} index={index} />
				))}
		</ul>
	);
};

LocationList.propTypes = {
	data: PropTypes.arrayOf(
		PropTypes.shape({
			count: PropTypes.number.isRequired,
			label: PropTypes.string.isRequired,
			type: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired
		})
	)
};

export default LocationList;
