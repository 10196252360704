import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLabels, useRequestData } from 'wsm-common-data';
import { Panel, Collapse } from 'react-bootstrap';

import FacetTitle from './FacetTitle';
import FacetGroup from './FacetGroup';
import { FACET_OVERRIDE_TYPE_SELECT } from '../global-constants';

const MultiFacetGroup = ({
	facets,
	groupId,
	groupTitleLabel,
	openDetailsModal,
	detailsLinkData,
	collapsed
}) => {
	const [isCollapsed, setCollapsed] = useState(collapsed);
	const pageAlias = useSelector((state) => state?.moreRequestData?.pageAlias);
	const { windowId } = useRequestData();

	const handleCollapse = () => {
		setCollapsed(!isCollapsed);
	};

	const onToggleCollapsed = () => {
		document
			.getElementById(
				`panel-collapse-${groupId}-${pageAlias}-${windowId}`
			)
			.removeAttribute('aria-expanded');
	};

	const expandIcon = isCollapsed ? 'ddc-icon-expand' : 'ddc-icon-collapse';

	const labels = useLabels();
	const facetTitle = labels.get(groupTitleLabel);

	const hasContentOrLabel =
		detailsLinkData &&
		(detailsLinkData.mainDetail ||
			detailsLinkData.subContent?.length !== 0);
	return (
		<Panel
			className="facet-list-group"
			id={groupId}
			data-facet-group={groupId}
			expanded={!isCollapsed}
			onToggle={onToggleCollapsed}
		>
			<Panel.Heading role="presentation">
				<Panel.Title>
					<button
						aria-expanded={!isCollapsed}
						aria-controls={`panel-collapse-${groupId}-${pageAlias}-${windowId}`}
						id={`panel-title-${groupId}-${pageAlias}-${windowId}`}
						type="button"
						className="btn btn-unstyled w-100"
						onClick={handleCollapse}
					>
						<FacetTitle {...{ facetTitle, expandIcon }} />
					</button>
				</Panel.Title>
			</Panel.Heading>
			<Collapse
				in={!isCollapsed}
				id={`panel-collapse-${groupId}-${pageAlias}-${windowId}`}
				role="region"
				aria-labelledby={`panel-title-${groupId}-${pageAlias}-${windowId}`}
				onEntered={onToggleCollapsed}
				onExited={onToggleCollapsed}
			>
				<div className="panel-collapse">
					<Panel.Body>
						{(facets || []).map(({ facet, facetSelections }) => (
							<FacetGroup
								facet={facet}
								selectedValues={
									facetSelections
										? facetSelections.values
										: []
								}
								renderPanel={false}
								// TODO: This should be configurable per group/facet
								overrideType={FACET_OVERRIDE_TYPE_SELECT}
								key={`facetgroup-${facet.id}`}
							/>
						))}
						{hasContentOrLabel && (
							<button
								type="button"
								className="text-link mt-3 text-decoration-underline text-primary text-left"
								onClick={() =>
									openDetailsModal(
										facetTitle,
										detailsLinkData,
										true
									)
								}
							>
								<span className="ddc-font-size-xsmall">
									{labels.get('ABOUT')} {facetTitle}
								</span>
							</button>
						)}
					</Panel.Body>
				</div>
			</Collapse>
		</Panel>
	);
};

MultiFacetGroup.propTypes = {
	facets: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	groupId: PropTypes.string.isRequired,
	groupTitleLabel: PropTypes.string.isRequired,
	collapsed: PropTypes.bool.isRequired,
	openDetailsModal: PropTypes.func,
	detailsLinkData: PropTypes.shape({
		mainDetail: PropTypes.string,
		subContent: PropTypes.shape([])
	})
};

export default MultiFacetGroup;
