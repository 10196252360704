import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Panel, Collapse } from 'react-bootstrap';
import { useLabels, useRequestData } from 'wsm-common-data';
import { trackEvent } from 'ddc-track-event';
import PropTypes from 'prop-types';
import { ClosestDealerMarkup } from 'wsm-location-modal';
import DistanceShipping from './DistanceShipping';
import FacetTitle from './FacetTitle';

const ClosestDealerWrapper = ({
	closestAccounts,
	isRenderPortal,
	locationList,
	showLocationTab
}) => {
	const labels = useLabels();
	const pageAlias = useSelector((state) => state?.moreRequestData?.pageAlias);
	const { windowId, widgetName } = useRequestData();
	const [isCollapsed, setCollapsed] = useState(false);
	const facetTitle = labels.get('DISTANCE_SHIPPING');

	const {
		data: closestDealers,
		appliedParams,
		prefs = {}
	} = closestAccounts || {};

	const { showYourStore } = prefs;

	const isShowYourStore = showYourStore === 'true';

	const expandIcon = isCollapsed ? 'ddc-icon-expand' : 'ddc-icon-collapse';
	if (!closestDealers?.length) {
		return <></>;
	}
	const closestDealer = closestDealers[0];

	const { postalCode } = appliedParams;

	const openModal = () => {
		if (!isShowYourStore) {
			return;
		}
		window?.DDC?.pubsub?.publish('location-modal/show-modal');
	};

	const onToggleCollapsed = () => {
		document
			.getElementById(
				`panel-collapse-distance-shipping-${pageAlias}-${windowId}`
			)
			.removeAttribute('aria-expanded');
	};

	const handleCollapse = () => {
		setCollapsed(!isCollapsed);

		if (!isCollapsed) {
			trackEvent(widgetName, windowId, {
				action: 'collapsed',
				element: 'distance-shipping accordion',
				result: 'Accordion collapsed'
			});
		} else {
			trackEvent(widgetName, windowId, {
				action: 'expanded',
				element: 'distance-shipping accordion',
				result: 'Accordion expanded'
			});
		}
	};

	return (
		<>
			<Panel
				className="facet-list-group"
				id="distance-shipping-facet"
				data-facet-group="distance-shipping-facet"
				expanded={!isCollapsed}
				onToggle={onToggleCollapsed}
			>
				<Panel.Heading role="presentation">
					<Panel.Title>
						<button
							type="button"
							aria-expanded={!isCollapsed}
							aria-controls={`panel-collapse-distance-shipping-${pageAlias}-${windowId}`}
							id={`panel-title-distance-shipping-${pageAlias}-${windowId}`}
							className="btn btn-unstyled w-100 p-0 border-0"
							onClick={handleCollapse}
						>
							<FacetTitle {...{ facetTitle, expandIcon }} />
						</button>
					</Panel.Title>
				</Panel.Heading>
				<Collapse
					in={!isCollapsed}
					id={`panel-collapse-distance-shipping-${pageAlias}-${windowId}`}
					role="region"
					aria-labelledby={`panel-title-distance-shipping-${pageAlias}-${windowId}`}
					onEntered={onToggleCollapsed}
					onExited={onToggleCollapsed}
				>
					<div className="panel-collapse">
						<Panel.Body>
							<ClosestDealerMarkup
								closestDealer={closestDealer}
								userPostalCode={postalCode}
								onClick={openModal}
								containerClassNames={[
									'text-left',
									'facet-list-group panel panel-default p-0 border-bottom-0',
									isRenderPortal
										? 'd-flex flex-wrap align-items-center flex-gap-row-1-rem'
										: ''
								]}
								isRenderPortal={isRenderPortal}
							/>
							<DistanceShipping
								closestDealers={closestDealers}
								showLocationTab={showLocationTab}
								locationList={locationList}
							/>
						</Panel.Body>
					</div>
				</Collapse>
			</Panel>
		</>
	);
};

ClosestDealerWrapper.propTypes = {
	isRenderPortal: PropTypes.string.isRequired,
	closestAccounts: PropTypes.shape({
		data: PropTypes.arrayOf(
			PropTypes.shape({
				name: PropTypes.string.isRequired
			})
		),
		appliedParams: PropTypes.shape({
			postalCode: PropTypes.string.isRequired
		})
	}),
	locationList: PropTypes.arrayOf(
		PropTypes.shape({
			count: PropTypes.number.isRequired,
			label: PropTypes.string.isRequired,
			type: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired
		})
	),
	showLocationTab: PropTypes.bool.isRequired
};

export default ClosestDealerWrapper;
