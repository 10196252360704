import React, { useState, useEffect, Suspense } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useRequestData, useLabels } from 'wsm-common-data';
import { trackEvent } from 'ddc-track-event';
import { Panel, Collapse } from 'react-bootstrap';
import { DropdownContainer } from 'wsm-dropdown';

import { isArrayEqual } from '../utilities/equal-array';
import Facet from './Facet';
import FacetTitle from './FacetTitle';
import { transformUserRangeData } from '../utilities/facet-user-range';
import { FACET_OVERRIDE_TYPE_SELECT, priceFacetIds } from '../global-constants';
import FacetSkeleton from './FacetSkeleton';
import WebIntegrationPlaceholder from './WebIntegrationPlaceholder';
import FacetGeoLocation from './geo-location/FacetGeoLocation';

const FacetUserRange = React.lazy(() =>
	import(
		/* webpackChunkName: "FacetUserRange" , webpackPrefetch: true */ './user-range/FacetUserRange'
	)
);

const FacetGroup = ({
	facet,
	detailsLinkData,
	openDetailsModal,
	selectedValues,
	renderPanel = true,
	overrideType,
	geoLocationEnhanced
}) => {
	const {
		type,
		label,
		id: groupId,
		multiselect = undefined,
		values,
		collapsed
	} = facet || {};
	const facetType = multiselect ? 'checkbox' : 'radio';
	const { widgetName, windowId, deviceType } = useRequestData();
	const [isCollapsed, setCollapsed] = useState(collapsed);
	const labels = useLabels();
	const geoLocationLabel = labels.get('DISTANCE');
	let facetTitle = label;

	const pageAlias = useSelector((state) => state?.moreRequestData?.pageAlias);
	const isDesktop = deviceType === 'DESKTOP';

	// State derived from getAppliedFilters that represents currently selected facets
	const [userSelections, setUserSelections] = useState(selectedValues);

	useEffect(() => {
		setUserSelections(selectedValues);
	}, [selectedValues]);

	const updateFacets = (facetId, targetValue, event = null) => {
		const newSelectedIds = [];

		if (facetType === 'checkbox') {
			selectedValues.map((selected) =>
				newSelectedIds.push(selected.value)
			);
			// Remove selected value from array if already included
			const selectedIndex = newSelectedIds.indexOf(targetValue);
			if (selectedIndex !== -1) {
				newSelectedIds.splice(selectedIndex, 1);
			} else {
				// Add facet id to list of selected ids
				newSelectedIds.push(targetValue);
			}
			// Don't push anything to updatedSelections when the selection is ALL
		} else if (targetValue !== 'ALL') {
			newSelectedIds.push(targetValue);
		}

		window.DDC.InvData.setFilterSelections({
			filterName: facetId,
			filterValue: targetValue,
			filterValues: newSelectedIds,
			filterTitleText: facetTitle,
			isChip: false,
			isSelected: newSelectedIds.includes(targetValue),
			isSlider: false,
			element: event && event.target
		});

		setUserSelections(
			values.filter((selection) =>
				newSelectedIds.includes(selection.value)
			)
		);

		if (newSelectedIds.includes(targetValue)) {
			trackEvent(widgetName, windowId, {
				action: 'applied',
				element: `${facetId} selection`,
				elementCTA: targetValue,
				fieldValue: targetValue,
				result: 'Results updated with facet added',
				dataToExcludeFromWidgetUserEvent: {
					facetId,
					facetTitle
				}
			});
		} else {
			trackEvent(widgetName, windowId, {
				action: 'removed',
				element: `${facetId} selection`,
				elementCTA: targetValue,
				fieldValue: targetValue,
				result: 'Results updated with facet removed',
				dataToExcludeFromWidgetUserEvent: {
					facetId,
					facetTitle
				}
			});
		}
	};

	const handleDropdownChange = (option, facetId) => {
		const { selectedItem } = option;
		const { value } = selectedItem;
		updateFacets(facetId, value);
	};

	const handleChange = (event, facetId) => {
		const targetValue = event.target.value;
		updateFacets(facetId, targetValue, event);
	};

	/**
	 * Creates a list of Facet components for each FacetGroup
	 *
	 * @event handleChange
	 * @returns {Facet[] || FacetUserRange || null} Facet components
	 */

	const getFacets = () => {
		if (values?.length) {
			// TODO: Move this to configurable option in composer and facet-config
			if (overrideType && overrideType === FACET_OVERRIDE_TYPE_SELECT) {
				return (
					<DropdownContainer
						group={facet}
						onChange={handleDropdownChange}
						groupTitle={facetTitle}
						inputClass="border w-100 dropdown-toggle btn font-weight-normal text-left text-neutral-1000 bg-neutral-0"
					/>
				);
			}

			if (type === 'USER_RANGE') {
				const [facetValues, noPriceObj] = transformUserRangeData(
					facet,
					!!multiselect
				);
				if (facetValues) {
					return (
						<Suspense fallback={<FacetSkeleton />}>
							<FacetUserRange
								facetValues={facetValues}
								selectedValues={selectedValues}
								noPriceObj={noPriceObj}
								facetId={facet.id}
								isMultiselect={!!multiselect}
								facetTitle={facetTitle}
							/>
						</Suspense>
					);
				} else {
					return null;
				}
			}
			if (type === 'GEO') {
				facetTitle = geoLocationLabel;
				return <FacetGeoLocation facetTitle={facetTitle} />;
			}

			return (
				<ul className="list-unstyled line-height-condensed">
					{values?.map((item) => {
						// values = DDC.InvData.facets[this facet group].values
						const {
							label: facetLabel,
							value: facetValue,
							count: facetCount
						} = item;
						let selected = !!userSelections.find(
							(selection) => selection.value === facetValue
						);
						if (!userSelections.length && facetValue === 'ALL') {
							selected = true;
						}
						return (
							<Facet
								facetLabel={facetLabel}
								facetType={facetType}
								facetValue={facetValue}
								facetCount={facetCount}
								groupId={groupId}
								isSelected={selected}
								isDisabled={false}
								onChange={handleChange}
								key={`${groupId}-${facetValue}`}
							/>
						);
					})}
				</ul>
			);
		} else {
			return null;
		}
	};

	const [isInteracted, setInteracted] = useState(false);
	const handleCollapse = () => {
		setCollapsed(!isCollapsed);
		setInteracted(true);

		if (!isCollapsed) {
			trackEvent(widgetName, windowId, {
				action: 'collapsed',
				element: `${groupId} accordion`,
				result: 'Accordion collapsed'
			});
		} else {
			trackEvent(widgetName, windowId, {
				action: 'expanded',
				element: `${groupId} accordion`,
				result: 'Accordion expanded'
			});
		}
	};

	const onToggleCollapsed = () => {
		document
			.getElementById(
				`panel-collapse-${groupId}-${pageAlias}-${windowId}`
			)
			.removeAttribute('aria-expanded');
	};
	const expandIcon = isCollapsed ? 'ddc-icon-expand' : 'ddc-icon-collapse';

	let facets = null;
	// Display request facets only when the panel is open
	// or user has previously toggled the panel
	// or if we're not rendering a panel
	if (!renderPanel || !isCollapsed || isInteracted) {
		facets = getFacets();

		if (!facets) {
			return null;
		}
	}

	if (!renderPanel) {
		return <React.Fragment>{facets}</React.Fragment>;
	}

	const wiapiPlaceholder = priceFacetIds.includes(groupId) ? (
		<WebIntegrationPlaceholder
			location="search-facet-pricing"
			locationId={groupId}
		/>
	) : null;

	let hasDetails = detailsLinkData?.details;

	//	If normalFuelType facet contains only options :
	// 		Gasoline and Diesel
	// 		Gasoline and flexible
	// 		Diesel and Flexible
	//	Not show the about EV link
	if (groupId === 'normalFuelType' && values.length === 2) {
		const fuelMatches = [
			['Gasoline', 'Diesel'],
			['Gasoline', 'Flexible'],
			['Diesel', 'Flexible']
		];
		const fuelTypes = values?.map((fuelType) => fuelType?.value);
		const isOnlyGasOrDiesel = !!fuelMatches?.find((match) =>
			isArrayEqual(match, fuelTypes)
		);
		hasDetails = !isOnlyGasOrDiesel;
	}

	if (type === 'GEO' && geoLocationEnhanced) {
		return <></>;
	}

	return (
		<Panel
			className="facet-list-group"
			id={groupId}
			data-facet-group={groupId}
			expanded={!isCollapsed}
			onToggle={onToggleCollapsed}
		>
			<Panel.Heading role="presentation">
				<Panel.Title>
					<button
						type="button"
						aria-expanded={!isCollapsed}
						aria-controls={`panel-collapse-${groupId}-${pageAlias}-${windowId}`}
						id={`panel-title-${groupId}-${pageAlias}-${windowId}`}
						className="btn btn-unstyled w-100 p-0 border-0"
						onClick={handleCollapse}
					>
						<FacetTitle {...{ facetTitle, expandIcon }} />
					</button>
				</Panel.Title>
			</Panel.Heading>
			<Collapse
				in={!isCollapsed}
				id={`panel-collapse-${groupId}-${pageAlias}-${windowId}`}
				role="region"
				aria-labelledby={`panel-title-${groupId}-${pageAlias}-${windowId}`}
				onEntered={onToggleCollapsed}
				onExited={onToggleCollapsed}
			>
				<div className="panel-collapse">
					<Panel.Body>
						{facets}
						{wiapiPlaceholder}
						{hasDetails && (
							<button
								type="button"
								className={`text-link text-decoration-underline text-primary text-left ${
									isDesktop ? '' : 'mt-3'
								}`}
								onClick={() =>
									openDetailsModal(null, detailsLinkData)
								}
							>
								<span className="ddc-font-size-xsmall">
									{labels.get('ABOUT')}{' '}
									{detailsLinkData.aboutLink}
								</span>
							</button>
						)}
					</Panel.Body>
				</div>
			</Collapse>
		</Panel>
	);
};

FacetGroup.propTypes = {
	facet: PropTypes.shape({
		type: PropTypes.string.isRequired,
		label: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
		isCurrency: PropTypes.bool,
		multiselect: PropTypes.bool,
		values: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
		collapsed: PropTypes.bool.isRequired
	}).isRequired,
	detailsLinkData: PropTypes.shape({
		details: PropTypes.string,
		subText: PropTypes.string,
		aboutLink: PropTypes.string
	}),
	selectedValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	renderPanel: PropTypes.bool,
	overrideType: PropTypes.string,
	openDetailsModal: PropTypes.func,
	geoLocationEnhanced: PropTypes.func
};

export default FacetGroup;
