import React from 'react';
import PropTypes from 'prop-types';
/* eslint react/no-danger: 0 */

const LocationItem = ({ data, index }) => {
	const locationId = data.id;

	return (
		<li
			className="checkbox mb-lg-4 mb-3"
			key={`label-location-item-${locationId}-${index}`}
		>
			<label
				htmlFor={`label-location-item-${locationId}`}
				className="pl-0"
			>
				<input
					type="checkbox"
					value={data.value}
					id={`checkbox-store-facet-${locationId}`}
					className="ml-0"
				/>
				<span className="facet-list-facet-label-text-container">
					<span className="facet-list-facet-label-text">
						<span className="ml-0 ml-lg-4 pl-2 ddc-font-size-small">
							{data.label}
							<small>{data.count}</small>
						</span>
					</span>
				</span>
			</label>
		</li>
	);
};

LocationItem.propTypes = {
	data: PropTypes.arrayOf(
		PropTypes.shape({
			count: PropTypes.number.isRequired,
			label: PropTypes.string.isRequired,
			type: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired
		})
	),
	index: PropTypes.number.isRequired
};

export default LocationItem;
