import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import PropTypes from 'prop-types';
import FacetGeoLocation from './geo-location/FacetGeoLocation';
import LocationList from './LocationList';

const DistanceShipping = ({ showLocationTab, locationList }) => {
	const { geoZip } = window.DDC.InvData.getGeoData() || {};
	const { geoRadiusValues } = window.DDC.InvData.getGeoDataPrefs() || {};

	const onSelectTab = (key) => {
		if (key === 1) {
			window.DDC.InvData.setFilterSelections({
				filterName: 'geoRadius',
				filterValues: [],
				isSelected: true,
				isSlider: false,
				isChip: true
			});
		}
	};

	if (!geoZip || !geoRadiusValues) {
		return <></>;
	}

	if (showLocationTab) {
		return (
			<Tabs
				className="vehicle-card-tabs tabs facet-tabs"
				defaultActiveKey={0}
				onSelect={onSelectTab}
			>
				<Tab
					title="Shop by zip"
					id="vehicle-card-tabs-zip"
					eventKey={0}
					tabClassName="text-center ddc-font-size-xsmall"
				>
					<FacetGeoLocation disableLocation />
				</Tab>
				{locationList && (
					<Tab
						title="Shop by store"
						id="vehicle-card-tabs-store"
						eventKey={1}
						tabClassName="text-center ddc-font-size-xsmall"
					>
						<LocationList data={locationList} />
					</Tab>
				)}
			</Tabs>
		);
	}
	return (
		<div className="mt-3">
			<FacetGeoLocation disableLocation geoRangeLabel="true" />
		</div>
	);
};

DistanceShipping.propTypes = {
	locationList: PropTypes.arrayOf(
		PropTypes.shape({
			count: PropTypes.number.isRequired,
			label: PropTypes.string.isRequired,
			type: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired
		})
	),
	showLocationTab: PropTypes.bool
};

export default DistanceShipping;
